.p-wallet-info .wallet-header {
  height: 3rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  background-color: #fff;
  padding: 0.4rem 0.2rem;
}

.p-wallet-info .wallet-header > p {
  font-size: 0.28rem;
  color: #999;
}

.p-wallet-info .wallet-header > h2 {
  font-size: 1rem;
}

.p-wallet-info main {
  margin-top: 0.2rem;
  background: #fff;
}

.p-wallet-info main > .item {
  min-height: 1rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  border-top: 1px solid #ededed;
}

.p-wallet-info main > .item > span {
  width: -webkit-calc(100% - 6em);
  width: calc(100% - 6em);
  padding: 0.2rem 0;
}

.p-wallet-info main > .item:first-child {
  border-top: none;
}

.p-wallet-info main > .item label {
  width: 6em;
  font-size: 0.3rem;
  color: #999;
  padding: 0 1em;
}

.p-wallet-info main > .item .user-avatar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 80%;
}