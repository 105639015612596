.c-currency-detail-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #fff;
  border-bottom: 1px solid #ededed;
  color: #b3b3b3;
  padding: 0.3rem;
  font-size: 0.3rem;
}

.c-currency-detail-item .info {
  max-width: -webkit-calc(100% - 4em);
  max-width: calc(100% - 4em);
}

.c-currency-detail-item .info .time {
  color: #b3b3b3;
  font-size: 0.24rem;
}

.c-currency-detail-item .info .title {
  font-size: 0.3rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-bottom: 0.1rem;
}

.c-currency-detail-item .info .amount {
  font-size: 0.32rem;
}

.c-currency-detail-item.no-style {
  color: #333;
}

.c-currency-detail-item.no-style .amount {
  color: #ff9400;
}

.c-currency-detail-item.no-style .state-wainning {
  color: #b3b3b3;
}

.c-currency-detail-item.no-style .state-wainning .state {
  font-size: 0.24rem;
}

.c-currency-detail-item.in .title {
  color: #333;
}

.c-currency-detail-item.in .amount {
  color: #ff9400;
}

.c-currency-detail-item.in .state-fail {
  color: #b3b3b3;
}