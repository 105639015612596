.header {
  background-color: #59b6d7;
  color: #fff;
  border-bottom: none;
}

.header a {
  color: inherit;
}

.p-wallet .entry__group:first-of-type {
  margin-top: 0;
}

.m-wallet-panel {
  padding: 0.6rem 0.3rem;
  color: #fff;
  font-size: 0.28rem;
  background-color: #59b6d7;
}

.m-wallet-panel h3 {
  opacity: 0.7;
}

.m-wallet-panel p {
  margin-top: 0.8rem;
  font-size: 1rem;
  letter-spacing: 0.02rem;
}

footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 1rem;
}

footer p {
  font-size: 0.26rem;
  color: #999;
}