.p-wallet-recharge .paid-type {
  font-size: 0.3rem;
  color: #999;
}

.p-wallet-recharge .submit-btn-wrap {
  margin-bottom: 0.9rem;
}

.p-wallet-recharge .m-entry {
  width: 100%;
  padding: 0 0.2rem;
  background-color: #fff;
  margin-top: 0.2rem;
}