.m-entry {
  width: 100%;
  padding: 0 0.2rem;
  background-color: #fff;
}

.paid-type {
  font-size: 0.3rem;
  color: #999;
}

.withdraw-detail {
  width: 5em;
  text-align: right;
}