.c-wallet-detail-item {
  padding: 0.3rem;
  font-size: 0.3rem;
  line-height: 0.36rem;
  border-bottom: 1px solid #ededed;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #fff;
}

.c-wallet-detail-item .time {
  color: #b3b3b3;
  font-size: 0.24rem;
  text-align: center;
  line-height: 1;
}

.c-wallet-detail-item .title {
  margin: 0 0.3rem;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.c-wallet-detail-item .amount {
  width: 5em;
  text-align: right;
}

.c-wallet-detail-item .amount span {
  color: #ff9400;
}

.c-wallet-detail-item .amount span.gray {
  color: #b3b3b3;
}