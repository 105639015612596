.header {
  background: transparent;
  color: #fff;
  border-bottom: none;
  position: fixed;
}

.header a {
  color: inherit;
}

.m-currency-panel {
  padding: 1.5rem 0.3rem 0.6rem;
  color: #fff;
  font-size: 0.28rem;
  background: linear-gradient(-23deg, #9580d1 0%, #ab9cda 100%);
}

.m-currency-panel h3 {
  opacity: 0.7;
}

.m-currency-panel p {
  margin-top: 0.8rem;
  font-size: 1rem;
  letter-spacing: 0.02rem;
}

footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 1rem;
}

footer p {
  font-size: 0.26rem;
  color: #999;
}

.p-currency-recharge .m-currency-panel p {
  font-size: 0.6rem;
}

.p-currency-recharge .m-currency-panel p span {
  display: inline-block;
}

.p-currency-recharge .m-pinned-amount-btns {
  padding-bottom: 0;
}

.p-currency-recharge .m-pinned-amount-btns .buttons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.p-currency-recharge .m-pinned-amount-btns .buttons > button {
  margin: 0 0.2rem 0.3rem;
  width: -webkit-calc(33% - 0.4rem);
  width: calc(33% - 0.4rem);
}

.p-currency-recharge .paid-type {
  font-size: 0.3rem;
  color: #999;
}

.p-currency-recharge .submit-btn-wrap {
  margin-bottom: 0.9rem;
}

.p-currency-recharge .m-entry {
  line-height: 1;
  width: 100%;
  padding: 0 0.2rem;
  background-color: #fff;
  margin-top: 0.2rem;
}