.header {
  background: transparent;
  color: #fff;
  border-bottom: none;
  position: fixed;
}

.header a {
  color: inherit;
}

.m-currency-panel {
  padding: 1.5rem 0.3rem 0.6rem;
  color: #fff;
  font-size: 0.28rem;
  background: linear-gradient(-23deg, #9580d1 0%, #ab9cda 100%);
}

.m-currency-panel h3 {
  opacity: 0.7;
}

.m-currency-panel p {
  margin-top: 0.8rem;
  font-size: 1rem;
  letter-spacing: 0.02rem;
}

footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 1rem;
}

footer p {
  font-size: 0.26rem;
  color: #999;
}

.p-currency-withdraw .m-currency-panel p {
  font-size: 0.6rem;
}

.p-currency-withdraw .m-entry {
  width: 100%;
  padding: 0 0.2rem;
  background-color: #fff;
}

.p-currency-withdraw main {
  font-size: 0.26rem;
  padding: 0.3rem;
  line-height: 0.54rem;
  color: #999;
}

.p-currency-withdraw main .input-wrap {
  height: 0.9rem;
  background-color: #f4f5f5;
  border: solid 1px #dedede;
  margin-top: 0.3rem;
}

.p-currency-withdraw main .input-wrap input {
  background-color: transparent;
  height: 100%;
  width: 100%;
  text-align: center;
  font-size: 0.3rem;
}