.p-currency-detail .header {
  overflow: initial;
}

.p-currency-detail .type-switch-bar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 0.9rem;
}

.p-currency-detail .type-switch-bar > span {
  display: inline-block;
  height: 100%;
  padding: 0.22rem 0.12rem;
  color: #999;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.p-currency-detail .type-switch-bar > span.active {
  color: #333;
  border-bottom: 0.02rem solid #59b6d7;
}